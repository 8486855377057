import React, { useState, useEffect } from 'react';
import './bloginfo.scss';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { getContactInfo } from '../../../heplers/contactsList';
import Popup from 'reactjs-popup';
import FacebookLogo from '../../../assets/svgIcons/FacebookLogo.svg';
import WhatsappLogo from '../../../assets/svgIcons/WhatsappLogo.svg';
import LinkedinLogo from '../../../assets/svgIcons/LinkedinLogo.svg';
import Link from '../../../assets/svgIcons/Link.svg';
const ShareItems = [
  {
    id: 'link',
    text: 'Copy link',
    icon: Link,
    copy: true,
  },
  {
    id: 'facebook',
    text: 'Share on Facebook',
    icon: FacebookLogo,
    copy: false,
  },
  {
    id: 'linkedin',
    text: 'Share on Linkedin',
    icon: LinkedinLogo,
    copy: false,
  },
];

const BlogInfo = ({ item, onItemClick }) => {
  const { Title, DATE, URL, SEO, Image, AltText, Category, Tags, Content } = item;
  // const categoriesString = Category.map(el => {
  //   return ` ${el}`;
  // });

  const [isOpen, setIsOpen] = useState(false);
  const [popUp, setPopUp] = useState(null);
  const findPopup = () => {
    const popUp = document.getElementById('popup-root');
    setIsOpen(true);
    setPopUp(popUp);
  };

  useEffect(() => {
    const handleWheel = () => {
      if (isOpen) {
        setIsOpen(false);
        popUp?.remove();
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isOpen]);
  const close = () => {
    setIsOpen(false);
  };

  return (
    <div className="wrapper-flex-container">
      <div className="wrapper-header-wrapper">
        <div className="wrapper-header-wrapper-title-wrapper">
          <h1 className="wrapper-header-wrapper-title-wrapper-title">{Title}</h1>
        </div>
        <span className="wrapper-date">
          {`${DATE} |`}
          {Category.map((category, index) => (
            <a
              key={index}
              onClick={() => onItemClick('Category', category)}
              className="wrapper-date-category"
            >
              {category}
            </a>
          ))}
        </span>
      </div>
      <div className="wrapper-content">
        <div className="wrapper-image-wrapper">
          <img
            id={`${Title}blogInfo`}
            alt={AltText}
            className="wrapper-image"
            src={`/blogImages/${Image}.jpg`}
          ></img>
          <div className="wrapper-content-text-wrapper">
            {Content.map((contentItem, index) => {
              if (contentItem.Type === 'Heading') {
                return (
                  <h2 className="wrapper-content-subtitle" key={index}>
                    {contentItem.Text}
                  </h2>
                );
              } else if (contentItem.Type === 'Paragraph') {
                return (
                  <p className="wrapper-content-text-wrapper-text" key={index}>
                    {replaceWordWithLink(contentItem.Text, contentItem)}
                  </p>
                );
              } else {
                return null;
              }
            })}
            <div className="wrapper-contacts-wrapper">
              <div className="wrapper-contacts-wrapper-items">
                <Popup
                  onOpen={() => findPopup()}
                  keepTooltipInside=".wrapper-contacts-wrapper-items"
                  trigger={<div className={'shareButton'} id={'share'}></div>}
                  position={['top center', 'bottom right', 'bottom left']}
                  open={isOpen}
                  lockScroll={false}
                  className={'popupButton'}
                >
                  <div className={'sharePopUp'}>
                    <div className="sharePopUp-itemsHolder">
                      {ShareItems.map((shareItem, index) =>
                        getShareItem({ shareItem, item, index, callback: close }),
                      )}
                    </div>
                  </div>
                </Popup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getShareItem = ({ shareItem, item, index, callback }) => {
  const { Title, Content } = item;

  const currentHref = getCorrectHref({ shareItem, Title, Content: Content[0].Text });
  return (
    <a
      key={index}
      className="sharePopUp-itemHolder"
      href={currentHref}
      onClick={e => {
        callback();
        if (shareItem.copy) {
          handleCopyLinkClick(e);
        }
      }}
      target="_blank"
    >
      <img className={'sharePopUp-icon'} src={shareItem.icon} />
      <p>{shareItem.text}</p>
    </a>
  );
};

const handleCopyLinkClick = event => {
  event.preventDefault();
  const currentURL = window.location.href;
  navigator.clipboard.writeText(currentURL);
};

const getCorrectHref = ({ shareItem, Title, Content }) => {
  const url = window.location.href;

  const hrefs = [
    {
      id: 'facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      id: 'linkedin',
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    },
  ];

  return hrefs.find(x => x.id === shareItem.id)?.href;
};

// const replaceWordWithLink = (text, item) => {
//   // Разделяем строку на части, используя слово для замены как разделитель
//   if (!item.IntextLink) {
//     return text;
//   }
//   const wordToReplace = item.IntextLink[0]?.Text;
//   const link = item.IntextLink[0]?.Link;
//   const parts = text.split(wordToReplace);
//
//   // Создаем новый массив, вставляя JSX между частями
//   const modifiedText = parts.map((part, index) => (
//     <React.Fragment key={index}>
//       {part}
//       {index < parts.length - 1 && (
//         <a className={'link'} href={link} target="_blank" rel="noopener noreferrer">
//           {wordToReplace}
//         </a>
//       )}
//     </React.Fragment>
//   ));
//   // Возвращаем JSX
//   return modifiedText;
// };
const replaceWordWithLink = (text, item) => {
  if (!item.IntextLink) {
    return text;
  }

  // Функция для экранирования специальных символов в регулярных выражениях
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  // Создаем массив объектов с текстом и ссылкой
  const wordsToReplace = item.IntextLink.map((linkItem) => {
    const text = linkItem.Text[0];
    const link = linkItem.Link[0];
    return { text, link };
  });

  // Создаем объект для отслеживания замененных слов
  const replacedWords = {};

  // Создаем регулярное выражение для поиска всех слов
  const pattern = new RegExp(
    `(${wordsToReplace.map((w) => escapeRegExp(w.text)).join('|')})`,
    'g'
  );

  // Разделяем текст на части, включая слова для замены
  const parts = text.split(pattern);

  // Создаем новый массив, заменяя слова на ссылки
  const modifiedText = parts.map((part, index) => {
    const match = wordsToReplace.find((w) => w.text === part);
    if (match) {
      if (!replacedWords[match.text]) {
        // Заменяем только первое вхождение слова
        replacedWords[match.text] = true; // Отмечаем слово как замененное
        return (
          <a
            key={index}
            className={'link'}
            href={match.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {match.text}
          </a>
        );
      } else {
        // Оставляем последующие вхождения без изменений
        return part;
      }
    } else {
      return part;
    }
  });

  return modifiedText;
};


BlogInfo.propTypes = {
  itemId: PropTypes.any,
};
export default BlogInfo;
