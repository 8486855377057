import { titleBgColor } from '../../components/TitleWithOverlay';

export const servicesList = [
  {
    id: '1',
    title: `Software\nDevelopment &\nEngineering`,
    titleBackground: titleBgColor.blue,
    content: `We offer a wide range of software development services, including assisting our clients in maintaining 
    existing IT systems and applications, or building new applications. 
    Tring is a Genesys partner with many successful projects completed. We are experts in computer telephony integration technology.`,
    list: [
      {
        id: '1.1',
        item: 'Web App Development',
        imgClass: 'web-app',
      },
      {
        id: '1.2',
        item: 'System Integration',
        imgClass: 'system-integration',
      },
      {
        id: '1.3',
        item: 'Mobile Web Development',
        imgClass: 'mobile-web',
      },
      {
        id: '1.4',
        item: 'Third Party Integrations',
        imgClass: 'third-party',
      },
      {
        id: '1.5',
        item: 'UI & UX Design',
        imgClass: 'cookie',
      },
      {
        id: '1.6',
        item: 'DevOps Practices',
        imgClass: 'settings',
      },
    ],
  },
  {
    id: '2',
    title: `Business boost`,
    titleBackground: titleBgColor.purple,
    content: `Our clients deal with many flavors of customer service software used for CRM, 
    call center implementations, and ERP systems both in the cloud and on-premises. 
    The quality and cost of maintaining consistent customer interactions is critical. 
    We can help you get more business insights from your customer interactions, and improve the effectiveness and efficiency. 
    We can help you improve customer service interactions, including identification the best solution, integration 
    with your existing environment, and ongoing operations`,
    list: [
      {
        id: '2.1',
        item: 'Business Insights Improvement ',
        imgClass: 'case',
      },
      {
        id: '2.2',
        item: 'Business Effectiveness Advance',
        imgClass: 'chart',
      },
      {
        id: '2.3',
        item: 'Customer Interactions Analysis',
        imgClass: 'TV',
      },
    ],
  },
  {
    id: '3',
    title: `Continuous support`,
    titleBackground: titleBgColor.green,
    content: `We help corporations to develop unique solutions to address complex 
    business challenges and create the value through sustainable innovation. 
    As pragmatic consultants with an eye on execution, we help design and achieve market-leading 
    roadmaps by combining creative thinking, technology expertise, and global reach.`,
    list: [
      {
        id: '3.1',
        item: 'Maintenance & Support',
        imgClass: 'maintenance',
      },
      {
        id: '3.2',
        item: 'Artificial Intelligence',
        imgClass: 'artificial-intelligence',
      },
      {
        id: '3.3',
        item: 'IT Consulting',
        imgClass: 'person',
      },
      {
        id: '3.4',
        item: 'Cloud Infrastructure',
        imgClass: 'cloud-infrastructure',
      },
    ],
  },
  {
    id: '4',
    title: `Quality assurance`,
    titleBackground: titleBgColor.red,
    content: `Compared to manual testing, QA automation offers many benefits that can cut costs, 
    radically reduce time to market, optimize development workflows, and dramatically improve end-product quality. 
    ring is committed to reduce potential risks related to the releasing of immature software that could cause malfunctions. 
    In most cases,  if the software  performs badly in key features under testing, it leads to additional costs, both in 
    their correction itself,  even more in hazardous commercial or potential legal consequences for your organization. 
    Based on both manual and automated testing top-of-edge methodologies and tools, we provide our customers ease of mind by assessing and assuring quality 
    software products.`,
    list: [
      {
        id: '4.1',
        item: 'Accelerative Entering to Market',
        imgClass: 'rocket',
      },
      {
        id: '4.2',
        item: 'Quality Assurance Automation',
        imgClass: 'bug',
      },
      {
        id: '4.3',
        item: 'TOP QA Methodologies',
        imgClass: 'list-svg',
      },
    ],
  },
];
