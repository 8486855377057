import Button from '../../../Button';
import styles from './planItem.module.scss';
import AllInclusive from '../../../../assets/monobot/allinclusive.svg';

export const PlanItem = ({
  title,
  descBeforeAmount,
  descAfterAmount,
  amount,
  price,
  icon,
  buttonTitle,
  buttonLink,
  custom,
  optimal,
  maxBots,
  maxInteractions,
  maxUsers,
  blank,
}) => {
  return (
    <div className={`${styles.wrapper} ${optimal ? styles.wrapper__optimal : ''}`}>
      <div className={styles.contentHolder}>
        <div className={styles.planInfo}>
          <span className={styles.planTitle}>{title}</span>
          <div className={styles.priceContainer}>
            <img src={icon} />
            <span className={styles.priceHolder}>
              {custom ? (
                <span className={styles.custom}>Custom Pricing</span>
              ) : (
                <>
                  <span className={styles.price}>{price}</span> / month
                </>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <span>Limits</span>
        </div>
        <div className={styles.tableBody}>
          <div className={styles.tableRow}>
            <span>AI Agents</span>
            <div className={styles.rowWrapper}>
              <div className={styles.blackCircle}>
                {maxBots || (
                  <img className={styles.infIcon} src={AllInclusive} alt="AllInclusive" />
                )}
              </div>
            </div>
          </div>
          <div className={styles.tableRow}>
            <span>Chats/voice minutes</span>
            <div className={styles.rowWrapper}>
              <div className={styles.blackCircle}>
                {maxInteractions || (
                  <img className={styles.infIcon} src={AllInclusive} alt="AllInclusive" />
                )}
              </div>
            </div>
          </div>
          {/* <div className={styles.tableRow}>
            <span>Organization Members</span>
            <div className={styles.rowWrapper}>
              <div className={styles.blackCircle}>
                {maxUsers || (
                  <img className={styles.infIcon} src={AllInclusive} alt="AllInclusive" />
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          customClass={`${styles.button} ${optimal ? styles.button__optimal : ''}`}
          text={buttonTitle}
          link={buttonLink}
          blank={blank}
        />
      </div>
    </div>
  );
};
