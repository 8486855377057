import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import StartSection from './subcomponents/StartSection';
import StrengthsSection from './subcomponents/StrengthsSection';
import Figures from './subcomponents/FiguresSection';
import FormSection from './subcomponents/FormSection';
import ModelSection from './subcomponents/ModelSection';
import CustomersSection from './subcomponents/CustomersSection';
import FacebookPixel from '../../components/FacebookPixel/FacebookPixel';
import { useLocation } from 'react-router-dom';

//DO NOT delete: it is needed for Slider in CustomerSection
import 'slick-carousel/slick/slick.css';
import GoogleTagManager from '../../components/GoogleTagManager';
import 'slick-carousel/slick/slick-theme.css';
import './home.scss';
import HeadsetImg from '../../assets/homePage/headset.svg';
import CodeImg from '../../assets/homePage/code.svg';
import FrameImg from '../../assets/homePage/fame.svg';
import PMImg from '../../assets/homePage/pm.svg';

const strengthsList = [
  {
    id: '1',
    img: {
      src: HeadsetImg,
      alt: 'headset',
    },
    title: `CX SERVICE SOLUTIONS &  IP\u00A0TELEPHONY`,
    text: `Endorsed by Genesys, the leader in-cloud and on-premise customer experience solutions, we bring many years of technology 
    and infrastructure management expertise. Looking for a pool of technical resources for your software development? 
    We can provide dedicated resources to extend your team, without the overhead costs or a lengthy recruiting process.`,
  },
  {
    id: '2',
    img: {
      src: CodeImg,
      alt: 'code',
    },
    title: `FULL cycle of sOFTWARE DEVelopment`,
    text: `We've developed a unique and successful approach. Comprehensive collaboration with clients — our dedicated project 
    teams work closely with client teams on defining and tracking requirements and progress. Application of agile process 
    development, using established project management tools and methodology. Workaround varying location time differences 
    to respond promptly to client requests. Our team is ready to support any stage of a project, including collecting 
    initial requirements, design, definition, development, and continuous improvements.`,
  },
  {
    id: '3',
    img: {
      src: FrameImg,
      alt: 'frame',
    },
    title: `TEAMS OF SENIOR EXPERTS`,
    text: `Every project needs subject-matter experts who execute it best way. When your project is unique, 
    you need someone who can design and architect, not just execute. Our teams has subject-matter experts and 
    highly experienced industry leaders. Whatever the challenge, we are up for it.`,
  },
  {
    id: '4',
    img: {
      src: PMImg,
      alt: 'PM',
    },
    title: `EFFECTIVE PROJECT MANAGEMENT`,
    text: `Every successful project needs a plan, and the discipline to follow it. We are good at both :)`,
  },
];

const Home = () => {
  const scrollToCustomers = () => {
    const customersSection = document.getElementById('customers');
    if (!customersSection) return;

    const stopPoints = document.querySelectorAll('.stop-point');

    // Удаление класса 'stop-point' с элементов
    stopPoints.forEach(point => {
      point.classList.remove('stop-point');
    });

    // Добавление класса 'stop-point' ко всем элементам, кроме 'customers'
    stopPoints.forEach(point => {
      point.classList.add('stop-point');
    });

    // Скроллинг к customersSection
    customersSection.scrollIntoView({ behavior: 'smooth' });
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#customers') {
      scrollToCustomers();
    }
  }, [location]);

  return (
    <div className={'home page-wrapper'}>
      <Helmet>
        <title>Outsourcing IT Company</title>
        <meta
          name="description"
          content="Maximizing efficiency and success with Tring - your full-cycle software development outsourcing IT company."
        />
        <meta name="title" content="Tring: The Premier Outsourcing IT Company" />
        <script type="application/ld+json">
          {`{
              "@type": "BreadcrumbList",
              "@context": "https://schema.org",
              "itemListElement": [
                  {
                      "@type": "ListItem",
                      "position": 1,
                      "name": "Tring",
                      "item": "https://etring.com/"
                  },
                  {
                      "@type": "ListItem",
                      "position": 2,
                      "name": "Services",
                      "item": "https://etring.com/software-development-services/"
                  },
                  {
                      "@type": "ListItem",
                      "position": 3,
                      "name": "Genesys",
                      "item": "https://etring.com/genesys-contact-center-solutions/"
                  },
                  {
                      "@type": "ListItem",
                      "position": 4,
                      "name": "Career",
                      "item": "https://etring.com/career/"
                  },
                  {
                      "@type": "ListItem",
                      "position": 5,
                      "name": "Contacts",
                      "item": "https://etring.com/contacts/"
                  }
              ]
            }`}
        </script>
        <GoogleTagManager />
        <FacebookPixel />
      </Helmet>
      <StartSection />
      <StrengthsSection
        strengthsList={strengthsList}
        title={'our competitive ability & '}
        animatedTitle={'strengths'}
      />
      <ModelSection />
      <Figures />
      <CustomersSection />
      <FormSection />
    </div>
  );
};

export default Home;
