import React from 'react';

import SupportSection from './subcomponents/SupportSection';
import SolutionSection from './subcomponents/SolutionSection';
import ServicesSection from './subcomponents/ServicesSection';
import ResultsSection from './subcomponents/ResultSection';
import FormSectionGenesys from './subcomponents/FormSection';
import GoogleTagManager from '../../components/GoogleTagManager';
import FacebookPixel from '../../components/FacebookPixel/FacebookPixel';

import './genesys.scss';
import { Helmet } from 'react-helmet';

const Genesys = () => {
  return (
    <div className="genesys page-wrapper">
      <Helmet>
        <title>Genesys Contact Center Solutions Provider</title>
        <meta name="description" content="Discover exceptional Tring software development and enhance your contact center solutions with Genesys technology. Maximize efficiency and drive success now." />
        <meta name="title" content="Genesys Contact Center Solutions Provider " />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Genesys",
                "item": "https://etring.com/genesys-contact-center-solutions/"
              }]
            }`}
        </script>
        <GoogleTagManager />
        <FacebookPixel />
      </Helmet>
      <SupportSection />
      <SolutionSection />
      <ServicesSection />
      <ResultsSection />
      <FormSectionGenesys />
    </div>
  );
};

export default Genesys;
