import PropTypes from 'prop-types';
import './FooterItem.scss';
import { useNavigate } from 'react-router';
import {Link} from "react-router-dom";

export const FooterItem = ({ keyIndex, title, link, isDisabled, footer, id, animated = true }) => {
  const navigateTo = useNavigate();

  return (
    <li key={keyIndex} className={`footer-item${footer ? '-rights' : ''}`}>
      <Link
        rel="noreferrer"
        // onClick={e => {
        //   if (isDisabled) e.preventDefault();
        //   // navigateTo(link);
        // }}
        target={animated ? '_blank' : '_self'}
        to={link}
      >
        {!animated ? (
          <div className="footer-notAnimatedItem">
            <p>{title}</p>
          </div>
        ) : (
          <div className="footer-slider">
            <p>{title}</p>
            {!footer && <p>{title}</p>}
          </div>
        )}
        {/* <div className="footer-slider">
          <p>{title}</p>
          {!footer && !animated && <p>{title}</p>}
        </div> */}
      </Link>
    </li>
  );
};

FooterItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  footer: PropTypes.bool,
  keyIndex: PropTypes.any,
  id: PropTypes.string,
};
