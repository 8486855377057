import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './NavItem.scss';
import { TitleWithPartlyOverlay } from '../TitleWithOverlay';

export const NavItem = ({ title, link, closeMenu, labelOnTop }) => {
  return (
    <li className={'menu-item'}>
      <NavLink to={link} onClick={closeMenu}>
        <>
          {' '}
          {labelOnTop && (
            <TitleWithPartlyOverlay
              className={'labelOnTop'}
              bgColor="title-overlay--blue"
              customFontSize={true}
              title={{
                animated: labelOnTop,
              }}
            />
          )}
          <span className={'nav-title'}>
            <span>{title}</span>
          </span>
        </>
      </NavLink>
    </li>
  );
};

NavItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  closeMenu: PropTypes.func,
};
