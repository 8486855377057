import {useState, useEffect} from 'react'
import PropTypes from "prop-types";
import {createPortal} from "react-dom";
import Button, {BackButton} from "../../components/Button";
import Form from "../../components/Form";
import { useNavigate } from "react-router-dom";

export const Portal = ({ children, wrapperId='portal' })=> {
  const createWrapperAndAppendToBody = () => {
    const wrapperElement = document.createElement('div')
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);

    return wrapperElement;
  }

  let element = document.getElementById(wrapperId);
  // if element is not found with wrapperId or wrapperId is not provided,
  // create and append to body
  if (!element) element = createWrapperAndAppendToBody(wrapperId);

  return createPortal(children, element);
}


const CareerModal = ({isOpen, onClose, additionalData}) => {
  const [isApplied, setApplied] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if(isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return ()=> document.body.style.overflow = 'auto'
  }, [isOpen])

  const closeModal = () => onClose()

  const closeModalAndGoBack = () => {
    onClose()
    navigate('/career')
  }

  const overLayClickHandler = () => onClose()

  const onFormSubmit = () => setApplied(true)

  const renderContentForContact = () => {
    return (
      <>
        <h2 className='modal-title'>your contacts</h2>
        <p className='modal-text'>Leave your contacts below to receive our feedback on your CV</p>
        <div className='modal-form'>
          <Form isCVDataNeeded={true} CVDataForSubmit={additionalData} onFormSubmit={onFormSubmit}>
            <div className="modal-btns-wrapper">
              <BackButton text={'back'} onClick={closeModal} />
              <Button text={'send'} />
            </div>
          </Form>
        </div>
      </>
    )
  }

  const renderContentAfterSending = () => {
    return (
      <>
        <h2 className='modal-title'>thank you!</h2>
        <p className='modal-text'>We’ll check your CV and come back with feedback soon</p>
        <div className="modal-btns-wrapper center">
          <BackButton text={'close'} onClick={closeModalAndGoBack} />
        </div>
      </>
    )
  }

  if (!isOpen) return null;

  return (
    <>
      <div className="modal">
        <div className="modal-overlay" onClick={overLayClickHandler} />
        <div className="modal-content">
          {isApplied
            ? renderContentAfterSending()
            : renderContentForContact()
          }
        </div>
      </div>
    </>

  )
}

export default CareerModal


Portal.propTypes = {
  children:  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  wrapperId: PropTypes.string
}


CareerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  additionalData: PropTypes.any //file
}