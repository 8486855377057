import Button from '../../components/Button';
import Attach from '../../assets/attach.svg';
import CareerModal, { Portal } from './Modal';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VacanciesConsumer } from '../../context/VacanciesContext';
import Loader from '../../components/Loader';
import { shortenFileName } from '../../heplers/shortenFileName';
import VacancyDocParser from '../../heplers/vacancyDomParser';

const JobDescription = ({ vacancies, setVacancies }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [chosenVacancy, setChosenVacancy] = useState(null);
  const [loadedCVName, setLoadedCVName] = useState(null);
  const [loadedCV, setLoadedCV] = useState(null);
  const navigate = useNavigate();
  const { vacancyId } = useParams();
  const VacancyParser = new VacancyDocParser(setVacancies);

  const applyBtnHandler = () => setModalOpen(true);

  //refactor getting a file later
  const onFileAttachChange = event => {
    const allFiles = event.target.files;
    if (!allFiles.length) return;

    const file = allFiles[0];

    const shortFileName = shortenFileName({ fileName: file.name });

    shortFileName && setLoadedCVName(shortFileName);
    setLoadedCV(file);
  };

  useEffect(() => {
    if (!vacancies) {
      VacancyParser.getVacancies();
    }
    if (vacancies && vacancyId) setChosenVacancy(vacancies[vacancyId - 1]);
  }, [vacancies, vacancyId]);

  if (!vacancyId) navigate('/career');

  if (!chosenVacancy)
    return (
      <div className="job">
        <div className="loader-wrapper">
          <Loader />
        </div>
      </div>
    );

  return (
    <div className="job  page-flex-wrapper">
      <div className="job-inner">
        <div className="job-header">
          <h2 className="job-title title">{chosenVacancy.title}</h2>

          <div className="job-btns-wrapper">
            <label className={`job-file-upload ${loadedCVName && 'is-uploaded'}`}>
              <input type="file" onChange={onFileAttachChange} accept=".doc, .docx, .pdf" />
              <img src={Attach} alt="attach" />
              {loadedCVName ? loadedCVName : 'Attach'}
            </label>
            <Button text="apply" onClick={applyBtnHandler} disabled={!loadedCVName} />
          </div>
        </div>

        <div className="job-description">
          <div
            className="job-description-content"
            dangerouslySetInnerHTML={{ __html: chosenVacancy.description }}
          />
          <div className="job-description-shadow" />
        </div>

        <Portal wrapperId={'career-portal'}>
          <CareerModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            additionalData={loadedCV}
          />
        </Portal>
      </div>
    </div>
  );
};

const JobDescriptionWithConsumer = () => {
  return (
    <VacanciesConsumer>
      {({ vacancies, setVacancies }) => (
        <JobDescription vacancies={vacancies} setVacancies={setVacancies} />
      )}
    </VacanciesConsumer>
  );
};

export default JobDescriptionWithConsumer;
