import React from "react";
import {useIsInViewport} from "../../../heplers";
import {TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import Form from "../../../components/Form";
import AnimatedWrapper from "../../../components/animations/AnimatedWrapper";

const FormSection = () => {
  const observerOptions = { rootMargin: '0% 0% -20% 0%' }

  const [titleRef, isTitleInViewport] = useIsInViewport()
  const [textRef, isTextInViewport] = useIsInViewport(observerOptions)
  const [formRef, isFormInViewport] = useIsInViewport(observerOptions)

  return (
    <section className={`home-idea stop-point`}>
      <div className={`animation-wrapper self-sliding-ltr ${isTitleInViewport ? "self-sliding-ltr-in" : "self-sliding-ltr-out"}`} ref={titleRef}>
        <TitleWithPartlyOverlay
          title={{beforeAnimation: "we’d turn your idea into", animated: "a\u00A0strong", afterAnimation: "project"}}
          className={"home-idea-title"}
        />
      </div>
      <div className='home-idea-content'>
        <AnimatedWrapper
          renderedChild={({childClass}) => (
            <p className={`home-idea-text ${childClass}`}>
              Every idea matters. We would love to be your dream team. Just describe the essence of idea and our specialists
              will contact you shortly.
            </p>
          )}
          animationType={"slide"}
          animationDirection={"up"}
          additionalClass={isTextInViewport ? "slide-up-in" : "slide-up-out"}
          ref={textRef}
        />
        <div className={`animation-wrapper home-idea-form self-sliding-rtl ${isFormInViewport ? "self-sliding-rtl-in" : "self-sliding-rtl-out"}`} ref={formRef}>
          <Form requiredAdditionalField={"idea"}/>
        </div>
      </div>
    </section>
  )
}

export default FormSection
