import FlagUkr from '../../assets/flag-ukraine.svg';
import FlagUSA from '../../assets/flag-usa.svg';
import MapPin from '../../assets/map-pin.svg';

import './Contacts.scss';
import { useIsInViewport } from '../../heplers';
import { TitleWithPartlyOverlay } from '../../components/TitleWithOverlay';
import { Helmet } from 'react-helmet';
import FormSection from '../home2/subcomponents/FormSection';
import GoogleTagManager from '../../components/GoogleTagManager';
import FacebookPixel from '../../components/FacebookPixel/FacebookPixel';
import Icon from '../../components/Icon';
import Form from '../../components/Form';

export const locationsList = [
  {
    id: `ukr`,
    countryAbbr: `ukr`,
    address: 'Odesa, Ukraine',
    flag: {
      srcImg: FlagUkr,
      alt: 'a flag',
    },
    tel: '+38-094-140-0055',
    email: 'info@etring.com',
    mapLink:
      'https://www.google.com/maps/place/%D0%93%D0%B0%D0%B3%D0%B0%D1%80%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%B5+%D0%9F%D0%BB%D0%B0%D1%82%D0%BE,+5%2F1,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%B0,+65000/@46.4332162,30.7617616,17z/data=!3m1!4b1!4m5!3m4!1s0x40c6338afad59d87:0xc54b50247e8c8cde!8m2!3d46.4332162!4d30.7639503',
  },
  {
    id: 'usa',
    countryAbbr: 'usa',
    address: '1195 Awalt Dr, Mountain View, CA 94040, United States',
    flag: {
      srcImg: FlagUSA,
      alt: 'a flag',
    },
    tel: '+1 650 387 7978',
    email: 'info@etring.com',
    mapLink:
      'https://www.google.com/maps/place/1195+Awalt+Dr,+Mountain+View,+CA+94040,+%D0%A1%D0%A8%D0%90/@37.3629844,-122.0713964,17z/data=!3m1!4b1!4m5!3m4!1s0x808fb6c77134aab3:0x6075e76703d7de65!8m2!3d37.3629844!4d-122.0692077',
  },
];

const ContactLocation = ({ address, flag, tel, email, countryAbbr, mapLink }) => {
  return (
    <>
      <address>
        <div className="contactsWrapper-itemsHolder">
          <div className="contactsWrapper-item">
            <Icon isContacts={true} id={'address'} text={address} />
          </div>
          <div className="contactsWrapper-item">
            <Icon isContacts={true} id={'phone'} href={`tel:${tel}`} text={tel} />
          </div>
          <div className="contactsWrapper-item">
            <Icon isContacts={true} id={'email'} href={`mailto:${email}`} text={email} />
          </div>
        </div>
        <div className="contactsWrapper-socialsHolder">
          <Icon isDefault={true} id={'linkedin'} />
          <Icon isDefault={true} id={'facebook'} />
          {/* <Icon isDefault={true} id={'whatsapp'} /> */}
        </div>
      </address>
    </>
  );
};

const Contacts = () => {
  const [UkrRef, isUkrInView] = useIsInViewport();
  const [USARef, isUSAInView] = useIsInViewport();
  const [formRef, isFormInView] = useIsInViewport();

  return (
    <section>
      <div className="contactsWrapper page-flex-wrapper">
        {/*page-wrapper class is not needed, cause make min-height= 100vh*/}
        <Helmet title="Contact Us">
          <meta
            name="description"
            content="Looking to outsource your IT needs? Contact Tring – the premier full-cycle software development outsourcing IT company"
          />
          <meta name="title" content="Get in Touch: Contact Tring for Professional IT Services" />
          <script type="application/ld+json">
            {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Contacts",
                "item": "https://etring.com/contacts/"
              }]
            }`}
          </script>
          <GoogleTagManager />
          <FacebookPixel />
        </Helmet>
        <div className="contactsWrapper-inner">
          <div className="contactsWrapper-flexInfoHolder">
            <div
              className={`contactsWrapper-hire appear ${isFormInView ? 'appear-in' : 'appear-out'}`}
              ref={formRef}
            >
              <TitleWithPartlyOverlay
                title={{ beforeAnimation: 'every idea', animated: 'matters' }}
                className={'contactsForm-title'}
              />
              <div className="contactsWrapper-hire-form">
                <Form requiredAdditionalField={'idea'} />
              </div>
            </div>
            <div
              className={`contactsWrapper-contactsHolder appear ${
                isFormInView ? 'appear-in' : 'appear-out'
              }`}
            >
              <span className="ourLocationsTitle">Our locations</span>
              <ContactLocation {...locationsList[1]} />
            </div>
          </div>
          {/* <div className="contactsWrapper-location" ref={UkrRef}>
              <div className={`appear ${isUkrInView ? 'appear-in' : 'appear-out'}`}>
                <ContactLocation {...locationsList[0]} />
              </div>
            </div> */}
          <div
            className={`contactsWrapper-locations appear ${
              isFormInView ? 'appear-in' : 'appear-out'
            }`}
          >
            <div
              className={`contactsWrapper-map contactsWrapper-map__${locationsList[1].countryAbbr}`}
            >
              <a className="contactsWrapper-map__pin" href={locationsList[1].mapLink}>
                <img
                  src={MapPin}
                  alt="Outsourcing IT Company - Genesys Contact Center Solutions"
                  title="Outsourcing IT Company - Genesys Contact Center Solutions"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
