import {useRef} from "react"
import PropTypes from "prop-types";

const Progressbar = ({done, onProgressClick}) => {
  const ref = useRef()

  return (
    <div className={"progressbar"} onClick={(e)=> onProgressClick(e, ref)} ref={ref}>
      <div
        className={"progressbar-done"}
        style={{ width: `${done}%` }}
      ></div>
    </div>
  )
}

export default Progressbar

Progressbar.propTypes = {
  done: PropTypes.number,
  onMouseMove: PropTypes.func
}
