import './Blog.scss';
import Form from '../../components/Form';
import { TitleWithPartlyOverlay } from '../../components/TitleWithOverlay';
import { Helmet } from 'react-helmet';
import { SmallBlogItem } from '../../components/Blog/SmallBlogItem';
import { CategoryBar } from '../../components/Category';
import { useLocation } from 'react-router-dom';
import BlogInfo from '../../components/Blog/BlogInfo';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { useIsInViewport } from '../../heplers';
import GoogleTagManager from '../../components/GoogleTagManager';
import FacebookPixel from '../../components/FacebookPixel/FacebookPixel';
import { useNavigate } from 'react-router-dom';
import data from '../../heplers/blogData';
import { BlogItem } from '../../components/Blog/BlogItem';

const Blog = () => {
  const itemURLIsNullOrUndefined = () => {
    return itemURL === null || itemURL === undefined || itemURL === 'blog' || itemURL === '';
  };
  const navigateTo = useNavigate();
  // const blogData = getBlogItems(data);
  const [blogRef, inBlogView] = useIsInViewport();
  const [getSortedData, setSortedData] = useState(data);
  const itemURL = useLocation().pathname.split('/')[2];
  const isBlogInfo = !itemURLIsNullOrUndefined();

  const allCategories = [];
  const allTags = [];
  data.map(item => {
    item.Category.map(x => {
      allCategories.push(x);
    });
    item.Tags.map(x => {
      allTags.push(x);
    });
  });

  const getFilteredArray = array => {
    const arrayTemlpate = [...new Set(array)];
    return Array.from(arrayTemlpate);
  };

  const filteredCategoriesArray = getFilteredArray(allCategories);
  const filteredTagsArray = getFilteredArray(allTags);
  const blogItem = data.find(x => x.URL.split('/')[1] === itemURL);
  const [submited, setSubmited] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = getSortedData.slice(itemOffset, endOffset);
  const currentSmallItems = data.slice(0, 4);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = event => {
    const newOffset = isBlogInfo
      ? (event.selected * 1) % data.length
      : (event.selected * itemsPerPage) % getSortedData.length;
    // console.log('New Offset:', newOffset);
    setItemOffset(newOffset);

    if (isBlogInfo) {
      const targetItem = data.find(x => x.ID === newOffset);
      if (targetItem) {
        navigateTo(`/blog${targetItem.URL}`, { state: newOffset });
      }
    }
  };

  const title = blogItem?.Title;
  const content = blogItem?.Content[0].Text;
  const url = window.location.href;

  const sortTypes = [
    {
      type: 'Date',
    },
    {
      type: 'Category',
    },
    {
      type: 'Tag',
    },
  ];

  const sortBy = (sortType, info) => {
    switch (sortType) {
      case 'Date': {
        const filteredData = data.filter(x => {
          const dateParts = x.DATE.split(' ');
          const targetDate = info.split(' ');

          if (dateParts.length === 3) {
            dateParts.splice(1, 1);
          }

          if (dateParts.length === 2) {
            const [month, year] = dateParts;
            const [targetMonth, targetYear] = targetDate;
            return month === targetMonth && year === targetYear;
          }

          return false;
        });
        setSortedData(filteredData);
        break;
      }
      case 'Category': {
        setSortedData(data.filter(x => x.Category.indexOf(info) !== -1));
        break;
      }
      case 'Tag': {
        setSortedData(data.filter(x => x.Tags.indexOf(info) !== -1));
        break;
      }
      default:
        break;
    }
    setItemOffset(0);
    navigateTo('/blog');
  };

  return (
    <div className="blog page-flex-wrapper">
      {/*page-wrapper class is not needed, cause make min-height= 100vh*/}
      <Helmet title="Tring | Blog">
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={content} />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Blog",
                "item": "https://etring.com/blog/"
              }]
            }`}
        </script>
        <GoogleTagManager />
        <FacebookPixel />
      </Helmet>
      <div className="blogWrapper-inner">
        <div className={isBlogInfo ? 'blogWrapper-title-disabled' : 'blogWrapper-title'}>
          <TitleWithPartlyOverlay
            title={{ beforeAnimation: 'our', animated: ' blog' }}
            className={'contacts-title'}
          />
        </div>
        <div className="blogWrapper-content" ref={blogRef}>
          <div className={`blogWrapper-holder appear ${inBlogView ? 'appear-in' : 'appear-out'}`}>
            {!itemURLIsNullOrUndefined() ? (
              <BlogInfo item={blogItem} onItemClick={sortBy} />
            ) : (
              <div className="blogWrapper-flex-container">
                {currentItems.map((currentItem, index) => (
                  <div key={index} className="blogWrapper-flex-container-item">
                    <BlogItem item={currentItem} />
                  </div>
                ))}
              </div>
            )}
            <ReactPaginate
              nextLabel="next >"
              pageRangeDisplayed={isBlogInfo ? 0 : 3}
              marginPagesDisplayed={isBlogInfo ? 0 : 1}
              pageCount={isBlogInfo ? data.length : pageCount}
              onPageChange={handlePageClick}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item "
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName={isBlogInfo ? 'pagination disabled' : 'pagination'}
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>

          <div
            className={`blogWrapper-side-info-bar appear ${
              inBlogView ? 'appear-in' : 'appear-out'
            }`}
          >
            <div className="blogWrapper-side-info-bar-recrent-posts">
              <span className="blogWrapper-side-info-bar-label">Recent posts</span>
              <div className="scrollable-wrapper">
                <div className="blogWrapper-side-info-bar-items-holder">
                  {currentSmallItems.map((item, index) => (
                    <SmallBlogItem key={index} item={item} />
                  ))}
                  {/* <button className="blogWrapper-side-info-bar-button">See the full list</button> */}
                </div>
              </div>
            </div>
            <div className="blogWrapper-side-info-bar-categories">
              <span className="blogWrapper-side-info-bar-label">Categories</span>
              {filteredCategoriesArray.map((item, index) => (
                <a
                  onClick={() => {
                    sortBy(sortTypes[1].type, item);
                  }}
                  key={index}
                  className="blogWrapper-side-info-bar-categories-link"
                >
                  {item}
                </a>
              ))}
            </div>
            <div className="blogWrapper-side-info-bar-archives">
              <span className="blogWrapper-side-info-bar-label">archives</span>
              <a
                onClick={() => {
                  sortBy(sortTypes[0].type, 'August 2023');
                }}
                className="blogWrapper-side-info-bar-archives-link"
              >
                August 2023
              </a>
            </div>
            <div className="blogWrapper-side-info-bar-discover">
              <span className="blogWrapper-side-info-bar-label">EXPLORE WHAT MATTERS TO YOU</span>
              <div className="blogWrapper-side-info-bar-discover-wrapper">
                {filteredTagsArray.map((item, index) => (
                  <div key={index} className="blogWrapper-side-info-bar-discover-wrapper-item">
                    <CategoryBar
                      text={item}
                      onClick={() => {
                        sortBy(sortTypes[2].type, item);
                      }}
                    />
                  </div>
                ))}
              </div>
              {/* <button className="blogWrapper-side-info-bar-button">See more</button> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={!itemURLIsNullOrUndefined() ? 'hireSection-inner' : 'hireSection-inner-disabled'}
      >
        <TitleWithPartlyOverlay
          title={{ beforeAnimation: 'Every', animated: 'idea', afterAnimation: 'matters' }}
          className={'hireSection-title'}
        />
        <div className={`${submited ? 'hireSection-fields-success' : 'hireSection-fields-holder'}`}>
          <Form
            requiredAdditionalField={'idea'}
            onFormSubmit={() => {
              setSubmited(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
