import React, { useState, useRef, useEffect } from 'react';
import { TitleWithPartlyOverlay } from '../../../components/TitleWithOverlay';
import Slider from 'react-slick';
import CustomerGenesys from '../../../assets/homePage/customer-genesys.jpg';
import CustomerBetacom from '../../../assets/homePage/customer-betacom.jpg';
import Customer_1 from '../../../assets/homePage/customer _1.jpg';
import Placeholder from '../../../assets/homePage/placeholder.jpg';
import Customer_2 from '../../../assets/homePage/customer_2.jpg';
import CustomerGenesys2 from '../../../assets/homePage/genesys_professional_services.jpg';
import AnimatedWrapper from '../../../components/animations/AnimatedWrapper';
import Petr from '../../../assets/homePage/Petr.png';
import Stas from '../../../assets/homePage/St.png';
import Raj from '../../../assets/homePage/Raj.png';
import ReadMore from '../../../assets/homePage/ReadMore.svg';
import ReadLess from '../../../assets/homePage/ReadLess.svg';
import { useIsInViewport } from '../../../heplers';

const customersList = [
  {
    id: '1',
    items: [
      {
        id: 0,
        img: CustomerGenesys,
        name: 'Slava Zhakov',
        position: 'Genesys, Chief technical officer',
        feedback: `During our co-operation, Tring, Inc. has proven to be a responsible partner providing services of high 
          quality given time frames. We want to thank Mr. Arkady Erlikhman, CEO and owner of Tring, Inc., for the services provided. 
          We are fully satisfied with the professionalism of Tring, Inc. team, the level of knowledge, the innovative tools and methodologies used. 
          We would like to continue a long-term engagement with Tring, Inc.`,
      },
      {
        id: 1,
        img: Petr,
        name: 'Petr Makagon',
        position: 'Genesys, Development manager',
        feedback: `I have had the privilege of working with Tring for several years, and their team of software engineers, QA experts, and DevOps professionals have been instrumental in our projects. They played a pivotal role in designing and developing one of our flagship products from the ground up, and their contributions to various components within the Genesys Web Services umbrella have been outstanding. Their technical expertise, dedication, and collaborative spirit have made them an indispensable part of our success. I wholeheartedly recommend Tring for any software development needs.`,
      },
      {
        id: 2,
        img: Stas,
        name: 'Stas Zgardovski',
        position: 'Genesys, Development, VP',
        feedback: `The team I lead had a number of Tring engineers who
         participated in the development of the CX Contact solution,
          and I was thoroughly impressed. Tring developers demonstrated impressive speed and quality in their work even for experienced software professionals.
           Their ability to collaborate effectively with cross-teams, adapt quickly to our development guidelines,
            and maintain a high level of professionalism throughout the project were commendable. 
            Tring truly understands what it takes to deliver outstanding results and I highly recommend their services to anyone looking for top-notch software development.`,
      },
      {
        id: 3,
        img: Raj,
        name: 'Raj Patel',
        position: 'Genesys, SVP engineering',
        feedback: `Tring has been a trusted engineering partner for sometime. As SVP of engineering responsible for overall engineering execution I particularly appreciated Tring's flexibility, agility and foremost depth of engineering know how that was a great culture fit. Tring engaged with us with transparency and professionalism. I recommend Tring for software engineering and project needs without hesitation.`,
      },
    ],
  },
  {
    id: '2',
    img: CustomerBetacom,
    name: 'Gaston Ezequiel Di Filippo',
    position: 'betacom, Chief technical officer',
    feedback: `Our feedback is absolutely positive, we are always pleased to work with Tring. 
      Technicians of your company are very skilled for the topics asked and the communication in English is excellent. 
      Also, the pricing and timing are quite reasonable (could be better sometimes, but working in the same area I understand that 
      it's difficult and not always possible to improve these two points).`,
  },
  {
    id: '3',
    img: Placeholder,
    name: 'Igor Neyman',
    position: 'SteppeChange, President & CEO',
    feedback: `We attracted Tring for implementation of the Genesys solution in our company. 
      The team also helped us to integrate the products with another software. The project was successful and we continue to work with 
      Tring as they help us to implement changes and provide support.`,
  },
  {
    id: '4',
    img: Customer_2,
    name: 'Dan Kikinis',
    position: 'otopy, President & CEO',
    feedback: `We are grateful to the Tring team for their input and contribution. We involved the company for a specific 
      project with tough deadlines so the team worked under severe time pressure. During the project the team was properly organized and managed. 
      They demonstrated outstanding performance and could effectively work with our team and other vendors. 
      All deadlines were met with great quality.`,
  },
  {
    id: '5',
    img: CustomerGenesys2,
    name: 'Michael Varone',
    position: 'Program Director',
    feedback: `We were looking for a contractor to assist us with a custom software project and ended up choosing Tring, who came recommended by Genesys's development leadership. I am thrilled with the exceptional service provided by the Tring team. Their ability to transform ideas into reality, all while adhering to timelines and budgets, has truly exceeded our expectations.`,
  },
  // {
  //   id: 6,
  //   img: CustomerGenesys,
  //   name: 'Zultys',
  //   position: 'Genesys, SVP engineering',
  //   feedback: `We've engaged Tring's assistance for the Connect Web Admin project and are exploring collaboration for the MXreport redesign. Their technical team as well as Project Management are great to work with and excel in cooperation with our in-house engineers and Product Management. They are remarkably good at dissecting business tasks into detailed technical requirements. Their flexibility is also commendable, readily embracing our internal guidelines, especially concerning privacy and security. Tring ensures these non-negotiable aspects are seamlessly integrated into the design and implementation phases. With their extensive industry experience, they bring valuable insights, helping us comprehend and refine the challenges at hand and solutions for them.`,
  // },
];

const customersSliderDots = [
  {
    id: '1',
    title: 'genesys',
  },
  {
    id: '2',
    title: 'betacom',
  },
  {
    id: '3',
    title: 'SteppeChange',
  },
  {
    id: '4',
    title: 'Otopy',
  },
  {
    id: '5',
    title: 'Genesys Professional Services',
  },
  // {
  //   id: '6',
  //   title: 'Zultys',
  // },
];

const CustomersSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [titleRef, isTitleInView] = useIsInViewport();
  const [sliderRef, isSliderInView] = useIsInViewport();
  const [next, setNext] = useState(false);

  const renderSlide = ({ id, img, name, position, feedback }, items) => {
    return (
      <div className="slider">
        {items && items.length > 0 ? (
          <>
            {items.map(item => (
              <div key={item.id}>
                <SlideItem {...item} hasItems={true} />
              </div>
            ))}
          </>
        ) : (
          <SlideItem
            id={id}
            img={img}
            name={name}
            position={position}
            feedback={feedback}
            hasItems={false}
          />
        )}
        {items && (
          <div className={'customer-items-holder'}>
            <div className="customer-items">
              <div
                className={`customers-dots-wrapper slider-arrow slider-arrow-left ${
                  currentIndex === 0 ? 'gray' : ''
                }`}
                onClick={() => renderPreviousSlide(currentIndex, items)}
              ></div>
              <div className="customers-dots-wrapper">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className={`dot-item${index === currentIndex ? '' : '-active'}`}
                  ></div>
                ))}
              </div>
              <div
                onClick={() => renderNextSlide(currentIndex, items)}
                className={`customers-dots-wrapper slider-arrow slider-arrow-right ${
                  currentIndex === items.length - 1 ? 'gray' : ''
                }`}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const SlideItem = ({ id, img, name, position, feedback, hasItems }) => {
    const [expanded, setExpanded] = useState(false);
    const [mobileExpanded, setMobileExpanded] = useState(false);
    const [overflowed, setContentIsOverflowed] = useState(false);
    const [overflowedMobile, setContentIsOverflowedMobile] = useState(false);
    const contentRef = useRef(null);
    const mobileContentRef = useRef(null);

    useEffect(() => {
      if (id !== currentIndex) return;
      const isOverflowed = contentRef.current.scrollHeight > contentRef.current.clientHeight;
      const isOverflowedMobile =
        mobileContentRef.current.scrollHeight > mobileContentRef.current.clientHeight;
      setContentIsOverflowed(isOverflowed);
      setContentIsOverflowedMobile(isOverflowedMobile);
    }, [contentRef.current, mobileContentRef.current, next]);

    const handleExpand = () => {
      setExpanded(prevState => !prevState);
      if (contentRef.current) {
        if (!expanded) {
          const paragraph = contentRef.current;
          const scrollHeight = paragraph.scrollHeight;
          const height = paragraph.clientHeight;
          const maxScrollTop = scrollHeight - height;
          paragraph.scrollTo({
            top: maxScrollTop,
            behavior: 'smooth',
          });
        } else {
          const paragraph = contentRef.current;
          paragraph.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }
    };

    const handleMobileExpand = () => {
      setMobileExpanded(prevState => !prevState);
      if (!mobileExpanded) {
        const paragraph = contentRef.current;
        const scrollHeight = paragraph.scrollHeight;
        const height = paragraph.clientHeight;
        const maxScrollTop = scrollHeight - height;
        contentRef.current.scrollTo({
          top: maxScrollTop,
          behavior: 'smooth',
        });

        const paragraphMob = mobileContentRef.current;
        const scrollHeightMob = paragraphMob.scrollHeight;
        const heightMob = paragraphMob.clientHeight;
        const maxScrollTopMob = scrollHeightMob - heightMob;
        mobileContentRef.current.scrollTo({
          top: maxScrollTopMob,
          behavior: 'smooth',
        });
      } else {
        const paragraph = contentRef.current;
        paragraph.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        const paragraphMob = mobileContentRef.current;
        paragraphMob.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    return (
      <div className={`${!hasItems ? '' : id === currentIndex ? 'slide' : 'hidden-slide'}`}>
        <div className={`customer-slide${!hasItems ? '' : id === currentIndex ? '' : '-hidden'}`}>
          <div className="imageContainer">
            <img
              alt={'Outsourcing IT Company – Software Development Solutions '}
              title={'Outsourcing IT Company – Software Development Solutions '}
              src={img}
            />
            <div className="customer-slide-readmore-container">
              {(document.getElementById(`${name}${id}`)?.scrollHeight >
                document.getElementById(`${name}${id}`)?.clientHeight ||
                overflowed) && (
                <div
                  className="customer-slide-readmore-tablet"
                  onClick={() => handleMobileExpand()}
                >
                  {mobileExpanded ? 'READ LESS' : 'READ MORE'}
                </div>
              )}
            </div>
          </div>
          <div className="customer-slide-container">
            <div className="customer-slide-decorator decorator--small" />
            <h5 className="customer-slide-name">{name}</h5>
            <p className="customer-slide-position">{position}</p>

            <p
              className="customer-slide-feedback feedback--desktop"
              style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}
              ref={contentRef}
              id={`${name}${id}`}
            >
              {feedback}
            </p>
          </div>

          <div className="customer-slide-readmore-container">
            {feedback.length > 470 && id === currentIndex && (
              <div className="customer-slide-readmore-container-wrapper">
                <div
                  className="customer-slide-readmore"
                  onClick={() => {
                    handleExpand();
                  }}
                >
                  {!expanded && <span className={'dotsSpan'}>...</span>}
                  <img
                    src={expanded || mobileExpanded ? ReadLess : ReadMore}
                    className={`customer-slide-readmore-icon${
                      expanded || mobileExpanded ? '' : '-expanded'
                    }`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <p
          className="customer-slide-feedback feedback--mobile"
          style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}
          ref={mobileContentRef}
          id={`${name}${id}mobile`}
        >
          {feedback}
        </p>
        <div className="read-more-mobile-container">
          {(document.getElementById(`${name}${id}mobile`)?.scrollHeight >
            document.getElementById(`${name}${id}mobile`)?.clientHeight ||
            overflowedMobile) && (
            <div className="read-more-mobile" onClick={() => handleMobileExpand()}>
              {mobileExpanded ? 'READ LESS' : 'READ MORE'}
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderNextSlide = (currentIndex, items) => {
    const nextIndex = (currentIndex + 1) % items.length;
    renderSlide(items[nextIndex], items);
    setCurrentIndex(nextIndex);
  };

  const renderPreviousSlide = (currentIndex, items) => {
    const previousIndex = (currentIndex - 1 + items.length) % items.length;
    renderSlide(items[previousIndex], items);

    setCurrentIndex(previousIndex);
  };

  const renderCustomSlideDots = i => (
    <div className="slick-dot-wrapper">
      <p
        className="slick-dot-title"
        onClick={() => {
          setNext(!next);
        }}
      >
        {customersSliderDots[i].title}
      </p>
      <div className="slick-dot-decorator" />
    </div>
  );

  return (
    <section className="home-customers stop-point" id="customers">
      <AnimatedWrapper
        renderedChild={({ childClass }) => (
          <TitleWithPartlyOverlay
            title={{
              beforeAnimation: 'Our satisfied',
              animated: 'customers ',
              afterAnimation: 'say',
            }}
            className={`home-customers-title ${childClass}`}
          />
        )}
        animationType={'slide'}
        animationDirection={'up'}
        additionalClass={`home-customers-title ${isTitleInView ? 'slide-up-in' : 'slide-up-out'}`}
        ref={titleRef}
      />

      <div
        className={`home-customers-slider appear ${isSliderInView ? 'appear-in' : 'appear-out'}`}
        ref={sliderRef}
      >
        <Slider
          dots={true}
          fade={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          customPaging={renderCustomSlideDots}
        >
          {customersList.map(item => {
            return renderSlide(item.items ? item.items[0] : item, item.items);
          })}
        </Slider>
      </div>
    </section>
  );
};

export default CustomersSection;
