import React from 'react';

import { useIsInViewport } from '../../../heplers';
import { TitleWithPartlyOverlay } from '../../../components/TitleWithOverlay';
import Button from '../../../components/Button';

const StrengthItem = ({ id, img, title, text }) => {
  const [ref, isInViewport] = useIsInViewport();

  return (
    <li key={id} ref={ref}>
      <div className={`home-strengths-item stop-point`}>
        <img src={img.src} alt={img.alt} />
        <h2>{title}</h2>
        <p className="text">{text}</p>
      </div>
    </li>
  );
};

const StrengthsSection = ({ strengthsList, title, animatedTitle, style, button, custom }) => {
  const [ref, isInViewport] = useIsInViewport();

  return (
    <section className={'home-strengths-wrapper stop-point'}>
      <div
        className={`animation-wrapper appear ${isInViewport ? 'appear-in' : 'appear-out'}`}
        ref={ref}
      >
        <TitleWithPartlyOverlay
          title={{ beforeAnimation: title, animated: animatedTitle }}
          className={custom ? 'home-strengths-title-custom' : 'home-strengths-title'}
        />
      </div>

      <ul className={'home-strengths'}>
        {strengthsList.map(item => (
          <StrengthItem {...item} key={item.id} />
        ))}
      </ul>
      {button && (
        <div className="buttonHolder">
          <Button
            text="Create your Ai Agent Now"
            styles={{ width: '380px', height: '64px' }}
            link="https://monobot.ai/sign-up"
            blank={true}
          />
        </div>
      )}
    </section>
  );
};

export default StrengthsSection;
