export const contactsList = [
  {
    id: 'phone',
    text: '+1 650 387 7978',
    href: 'tel:+16503877978',
    subLink: 'Monobot assistant',
  },
  {
    id: 'email',
    text: 'info@etring.com',
    href: 'mailto:info@etring.com',
  },
  {
    id: 'facebook',
    href: 'https://www.facebook.com/profile.php?id=100095554621215',
    iconLink: true,
  },
  {
    id: 'linkedin',
    href: 'https://www.linkedin.com/company/e-tring',
    iconLink: true,
  },
  // {
  //   id: 'whatsapp',
  //   href: 'https://wa.me/15304365280',
  //   iconLink: true,
  // },
  {
    id: 'address',
    text: '1195 Awalt Dr, Mountain View, CA 94040, United States',
    href: '#',
  },
  {
    id: 'share',
    href: '#',
  },
];

export const getContactInfo = name => {
  return contactsList.find(x => x.id === name);
};
