import styles from './planSection.module.scss';
import { TitleWithPartlyOverlay } from '../../TitleWithOverlay';
import { PlanItem } from './PlanItem';
import PiggyIcon from '../../../assets/monobot/PiggyBank.svg';
import OfficeIcon from '../../../assets/monobot/BuildingOffice.svg';
import BusinessIcon from '../../../assets/monobot/Business Improvement.svg';
import BuildingIcon from '../../../assets/monobot/Building.svg';

const plans = [
  {
    title: 'Free Plan',
    descBeforeAmount: 'Up to',
    amount: ' 20 ',
    descAfterAmount: 'interactions per month',
    price: '$0',
    buttonTitle: 'TRY FOR FREE',
    buttonLInk: 'https://monobot.ai',
    blank: true,
    icon: PiggyIcon,
    maxUsers: 1,
    maxInteractions: 35,
    maxBots: 1,
  },
  {
    title: 'Basic Plan',
    descBeforeAmount: 'For under',
    amount: ' 1000/',
    descAfterAmount: 'month',
    price: '$200',
    buttonTitle: 'GET STARTED',
    buttonLInk: 'https://monobot.ai',
    blank: true,
    icon: BusinessIcon,
    optimal: true,
    maxUsers: 5,
    maxInteractions: 1000,
    maxBots: 10,
  },
  {
    title: 'Business Plan',
    descBeforeAmount: 'For',
    amount: ' 1000-10 000/',
    descAfterAmount: 'month',
    price: '$500',
    buttonTitle: 'GET STARTED',
    buttonLInk: 'https://monobot.ai',
    blank: true,
    icon: BuildingIcon,
    maxUsers: null,
    maxInteractions: 3000,
    maxBots: null,
  },
  {
    title: 'Enterprise Plan',
    descBeforeAmount: 'For',
    amount: ' 10 000+/',
    descAfterAmount: 'month',
    price: '0$',
    buttonTitle: 'Contact Sales',
    buttonLInk: 'tel:+16503877978',
    blank: false,
    icon: OfficeIcon,
    custom: true,
    maxUsers: null,
    maxInteractions: null,
    maxBots: null,
  },
];

export const PlansSection = () => {
  return (
    <section className="home-strengths-wrapper stop-point centered-block">
      <div className={styles.wrapper}>
        <div className={styles.titleHolder}>
          <div className={styles.title}>
            <TitleWithPartlyOverlay
              title={{
                beforeAnimation: 'Plans',
                animated: 'For any budget',
              }}
              className={'home-title'}
            />
          </div>
          <p className={styles.subtitle}>
            Set up AI-driven customer service without breaking the bank
          </p>
        </div>
        <div className={styles.itemsContainer}>
          {plans.map((x, index) => (
            <PlanItem
              key={index}
              title={x.title}
              descBeforeAmount={x.descBeforeAmount}
              descAfterAmount={x.descAfterAmount}
              amount={x.amount}
              price={x.price}
              buttonTitle={x.buttonTitle}
              buttonLink={x.buttonLInk}
              icon={x.icon}
              custom={x.custom}
              optimal={x.optimal}
              maxUsers={x.maxUsers}
              maxInteractions={x.maxInteractions}
              maxBots={x.maxBots}
              blank={x.blank}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
