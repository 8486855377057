import React from 'react';
import PropTypes from 'prop-types';

import './titleWithOverlay.scss';

export const titleBgColor = {
  blue: 'title-overlay--blue',
  darkBlue: 'title-overlay--dark-blue',
  purple: 'title-overlay--purple',
  green: 'title-overlay--green',
  red: 'title-overlay--red',
  orange: 'title-overlay--orange',
};

export const TitleWithOverlay = props => {
  const { title, styles, className, bgColor = titleBgColor.darkBlue } = props;
  return (
    <div className={`title-overlay ${className} ${bgColor}`}>
      <h2 style={styles} className={'title'}>
        {title}
      </h2>
    </div>
  );
};

//use \u00A0 if you need nbsp
export const TitleWithPartlyOverlay = props => {
  const {
    className,
    bgColor = titleBgColor.darkBlue,
    title: { beforeAnimation, animated, afterAnimation },
    style,
    customFontSize,
  } = props;

  return (
    <h1 style={customFontSize ? style : undefined} className={`title ${className}`}>
      {beforeAnimation}
      <span
        style={style}
        className={
          customFontSize
            ? `title-overlay-partly-custom ${bgColor}`
            : `title-overlay-partly ${bgColor}`
        }
      >
        <span>{animated}</span>
      </span>
      {afterAnimation}
    </h1>
  );
};

TitleWithOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  bgColor: PropTypes.string,
};
