import App from './App';
import { hydrate, render } from 'react-dom';

import './index.scss';
import FontFaceObserver from 'fontfaceobserver';
import TagManager from 'react-gtm-module';
import React from 'react';
import { VacanciesProvider } from './context/VacanciesContext';

//const root = ReactDOM.createRoot(document.getElementById('root'));

//for preloading fonts before page is loaded

const tagManagerArgs = {
  gtmId: 'GTM-WTM9XX9Z',
};
TagManager.initialize(tagManagerArgs);
new FontFaceObserver('Metropolis').load().then(() => {
  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    hydrate(
      <React.StrictMode>
        <VacanciesProvider>
          <App />
        </VacanciesProvider>
      </React.StrictMode>,
      rootElement,
    );
  } else {
    render(
      <React.StrictMode>
        <VacanciesProvider>
          <App />
        </VacanciesProvider>
      </React.StrictMode>,
      rootElement,
    );
  }

  // root.render(
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // );
});
