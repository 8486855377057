import { useEffect } from 'react';
import { BackButton } from '../Button';

const SuccessMessage = ({ onClose }) => {
  // useEffect(() => {
  //   let timerId = null;
  //   timerId = setTimeout(() => onClose(), 10000);

  //   return () => timerId && clearTimeout(timerId);
  // }, []);

  return (
    <div className={'success-message'}>
      <div className={'message'}>
        <h2 className="message-title">thank you!</h2>
        <p className="message-text">
          We appreciate you contacting us. One of our managers will get back in touch with you soon!
          Have a great day!
        </p>
        <div className="message-btn center">
          <BackButton text={'close'} onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
