import React, { useState, useRef, useEffect } from "react";
//import Poster from '../../assets/poster.jpg'
import Poster from '../../assets/poster_2.jpg'
import "./video.scss";

import TringVideo from '../../assets/Tring_Video.mp4'
import useVideoPlayer from "./useVideoPlayer";
import Progressbar from "./Progressbar";

const Video = () => {
  const [isControlsShown, setControlsShown] = useState(false)

  const videoElement = useRef(null);
  const videoContainer = useRef(null);
  const controlsTimer = useRef(0)

  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    toggleMute,
    toggleFullscreen,
    progressBarMouseHandler
  } = useVideoPlayer(videoElement, videoContainer);

  const clearControlsTimer = () =>  controlsTimer.current && clearTimeout(controlsTimer.current)
  const setControlsTimer = () => controlsTimer.current = setTimeout(() => setControlsShown(false), 3500)

  //click event is needed for IOS phones. Hover on IOS differ from Android hover on phone
  const onVideoWrapperClick = () => {
    clearControlsTimer()

    if (isControlsShown) {
      setControlsTimer()
    } else {
      setControlsShown(true)
      setControlsTimer()
    }
  }

  useEffect(()=> {
    return () => clearControlsTimer()
  }, [])

  return (
    <div className={`video-container ${playerState.isMockedFullscreen ? "mocked-fullscreen" : ""}`} ref={videoContainer}>
      <div
        className="video-wrapper"
        onClick={onVideoWrapperClick}
        onMouseEnter={()=> setControlsShown(true)}
        onMouseLeave={()=> setControlsShown(false)}
      >
        <video
          src={TringVideo}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
          className={"video"}
          poster={Poster}
          preload="metadata"
          webkit-playsinline={"true"}
          playsInline={true}
        />
        <div className="video-play-wrapper" onClick={togglePlay}>
          <div className={`video-play-button ${playerState.isPlaying ? "is-hidden" : ""}`}>
            <div />
          </div>
        </div>
        <div className={`controls ${isControlsShown ? "is-shown" : ""}`}>
          <div className="actions-btn" onClick={togglePlay} role="button">
            {!playerState.isPlaying ? (
              <div className="btn btn-play" />
            ) : (
              <div className="btn btn-pause" />
            )}
          </div>

          <div className="mute-btn" onClick={toggleMute} role="button">
            {!playerState.isMuted ? (
              <div className="btn btn-volume-unmute" />
            ) : (
              <div className="btn btn-volume-mute" />
            )}
          </div>

          <Progressbar done={playerState.progress} onProgressClick={progressBarMouseHandler}/>

          <div className="btn btn-full-screen" onClick={toggleFullscreen} role="button"/>
        </div>
      </div>
    </div>
  );
};

export default Video;