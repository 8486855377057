import { useState, useEffect } from "react";

const useVideoPlayer = (videoElement, videoContainer) => {

  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    isMockedFullscreen: false  //just for devises with don`t support fullscreen Api
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  useEffect(() => {

    playerState.isPlaying
      ? videoElement.current.play().catch((error) => {})
      : videoElement.current.pause();

  }, [playerState.isPlaying, videoElement]);


  const handleOnTimeUpdate = () => {
    if(videoElement.current) {
      const fullProgress = (videoElement.current.currentTime / videoElement.current.duration) * 100
      const progressWithFixedFloating = (fullProgress).toFixed(5);
      const progress = Number(progressWithFixedFloating)

      setPlayerState({
        ...playerState,
        progress,
      });
    }
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);

    const fullProgress = (videoElement.current.duration / 100) * manualChange
    const progressWithFixedFloating = (fullProgress).toFixed(5);
    const progress = Number(progressWithFixedFloating)

    videoElement.current.currentTime = progress;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  const progressBarMouseHandler = (event, progressBarRef) => {
    if (!event || !progressBarRef) return

    const parentParam = progressBarRef.current.getBoundingClientRect()
    const parentWidth = parentParam.width
    const clickedPosition = event.pageX - parentParam.left
    const manualPercentage = Math.floor(clickedPosition/parentWidth * 100)

    let manualChange = manualPercentage

    videoElement.current.currentTime = Number(((videoElement.current.duration / 100) * manualChange).toFixed(5));

    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  }

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  useEffect(() => {
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  function openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();/* Firefox */
    } else if (elem.enterFullscreen) { /* IOS, not always work*/
      elem.enterFullscreen()
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen()
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen(); /* Firefox */
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }

  const toggleFullscreen = () => {
    if (videoElement.current && videoContainer.current) {
      if(document.fullscreenEnabled) {
        const isFullScreen = document.isFullScreen || document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement

        isFullScreen && closeFullscreen()
        !isFullScreen && openFullscreen(videoContainer.current)
      } else {
        setPlayerState({
          ...playerState,
          isMockedFullscreen: !playerState.isMockedFullscreen,
        });
      }
    }
  }

  useEffect(() => {
    if(videoElement.current.ended) {
      setPlayerState({
        ...playerState,
        isPlaying: false,
      });

      //if set 0 white first slide is shown. Poster not appear
      videoElement.current.currentTime = .5;
    }
  }, [playerState.progress]);

  useEffect(() => {
    videoElement.current &&  handleOnTimeUpdate();
  }, [videoElement])


  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    toggleFullscreen,
    progressBarMouseHandler
  };
};

export default useVideoPlayer;