import Button from '../../Button';
import { TitleWithPartlyOverlay } from '../../TitleWithOverlay';
import styles from './intro.module.scss';
import MonobotScreen from '../../../assets/monobot/Monobot.jpg';
export const IntroSection = () => {
  const handleImageClick = () => {
    window.open('https://monobot.ai/', '_blank');
  };
  return (
    <section className="home-start stop-point centered-block">
      <div className={'home-start-screen-titles'}>
        <TitleWithPartlyOverlay
          title={{
            beforeAnimation: 'Improve Your Customer',
            animated: 'service',
          }}
          className={'home-title'}
        />
        <h1 className={'home-subtitleMonobot'}>with an AI Agent</h1>
        <div className={styles.contentHolder}>
          <p className="home-start-content-textMonobot">
            Take Customer Experience to a New Level. Stay in touch with your Customers 24/7.
            Increase conversions, leads and profits.
          </p>
          <div className={styles.buttonContentWraper}>
            <Button text="TRY NOW FOR FREE" link="https://monobot.ai/#democall" blank={true} />
            <p className={styles.label}>No credit card required. Quick setup. </p>
          </div>
        </div>
      </div>
      <div className="home-video-wrapper">
        <img className={styles.image} src={MonobotScreen} onClick={handleImageClick} />
      </div>
    </section>
  );
};
