import {useIsInViewport} from "../../../heplers";
import {titleBgColor, TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";

const SolutionSection = () => {
  const options = { rootMargin: "0px 0px 0px 0px"}
  const [titleRef, isTitleInView] =  useIsInViewport(options)

  return (
    <section className="genesys-solution stop-point">
      <div className="genesys-solution-col">
        <div className={`animation-wrapper genesys-solution-title appear ${isTitleInView ? "appear-in" : "appear-out"}`} ref={titleRef}>
          <TitleWithPartlyOverlay
            title={{beforeAnimation: "We offer solutions", animated: `on\u00A0Genesys`, afterAnimation: "base"}}
            className={"genesys-solution-title"}
            bgColor={titleBgColor.orange}
          />
        </div>

        <div className={`genesys-solution-item  appear ${isTitleInView ? "appear-in" : "appear-out"}`}>
          <div className={`svg head`} />
          <h3 className="genesys-solution-item-title">
            Genesys Customer Experience SolutionS
          </h3>
          <p className="genesys-solution-item-text">
            As customer requests always rise rapidly, we always provide the newest top-of-the-line management technology.
            Our team configures and customizes Genesys products to specific requirements and expectations, providing the highest
            levels quicker your customer experience according to your Genesys platform. We help you develop a seamless omnichannel experience for your customers to ensure them remain at the center of your call center operations.
          </p>
        </div>
      </div>

      <div  className="genesys-solution-col">
        <div className={`genesys-solution-item appear ${isTitleInView  ? "appear-in" : "appear-out"}`}>
          <div className={`svg interaction`} />
          <h3 className="genesys-solution-item-title">
            Genesys Planning & Technical Services
          </h3>
          <p className="genesys-solution-item-text">
            After many years of working experience, we have found out that every successful business must predict any failure.
            Owing to anticipate all possible outcomes your company will stay afloat, keeping its own position in the market.
            Also, it ensures maximizing your investments. Within 20-year experience, we’ve managed about a few dozens of
            successful Genesys implementations. Helping to develop the Genesys base code, allows us to manage all the technical
            services. You can inquire more about the suggested planning and technical services for your Genesys implementation.
          </p>
        </div>

        <div className={`genesys-solution-item appear ${isTitleInView ? "appear-in" : "appear-out"}`}>
          <div className={`svg note`} />
          <h3 className="genesys-solution-item-title">
            Genesys Employee Engagement Solutions
          </h3>
          <p className="genesys-solution-item-text">
            We know how to optimize the working process, delivering exceptional experiences and support to your customers.
            Inasmuch as we help your agents to support customers and involve efficient interaction with them. Сooperation with
            us will make your reports include the proper information, and data will always occur up-to-date. Although Genesys
            provides your agents all the needed tools and information for customer interactions, our main goal is to make sure
            whether you have the necessary support and your products run effectively and correctly.
          </p>
        </div>
      </div>
    </section>
  )
}

export default SolutionSection
