import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { VacanciesConsumer } from '../../context/VacanciesContext';
import VacancyDocParser from '../../heplers/vacancyDomParser';
import Loader from '../../components/Loader';
import { useIsInViewport } from '../../heplers';
import { TitleWithPartlyOverlay } from '../../components/TitleWithOverlay';
import GoogleTagManager from '../../components/GoogleTagManager';
import FacebookPixel from '../../components/FacebookPixel/FacebookPixel';

import './career.scss';

const CareerContent = ({ vacancies, vacanciesListErr, onRowClick }) => {
  const [contentRef, isContentView] = useIsInViewport();
  const renderErrorMessage = () => (
    <p className={'career-error'}>Careers are not available at the moment. Please come back late</p>
  );

  const renderTable = () => (
    <table className="career-table">
      <thead className={'career-table--header'}>
        <tr>
          <th>Job Title</th>
          <th>Location</th>
        </tr>
      </thead>

      <tbody>
        {vacancies &&
          vacancies.map(({ id, title, location }) => (
            <tr key={id + 1} onClick={() => onRowClick(id)}>
              <td>{title}</td>
              <td>{location}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <div className={`appear ${isContentView ? 'appear-in' : 'appear-out'}`} ref={contentRef}>
      <TitleWithPartlyOverlay
        title={{
          beforeAnimation: 'glad to welcome ',
          animated: 'specialists',
          afterAnimation: 'to our team',
        }}
        className={' career-title'}
      />

      {vacancies && !vacanciesListErr && renderTable()}
      {vacanciesListErr && renderErrorMessage()}
    </div>
  );
};

const Career = ({ vacancies, setVacancies }) => {
  const [vacanciesListErr, setVacanciesListErr] = useState(null);

  const navigate = useNavigate();
  const { vacancyId } = useParams();
  const VacancyParser = new VacancyDocParser(setVacancies);

  useEffect(() => {
    if (!vacancies) {
      VacancyParser.getVacancies().catch(err => {
        setVacanciesListErr(err);
      });
    }
  }, [vacancies]);

  const handleTableRowClick = id => navigate(`/career/${id}`);

  if (vacancyId) return <Outlet />;

  const renderLoader = () => (
    <div className="loader-wrapper">
      <Loader />
    </div>
  );

  return (
    <div className={`career page-flex-wrapper`}>
      <Helmet title="Tring | Career">
        <meta
          name="description"
          content="Join Tring company, we are glad to welcome specialists to our team"
        />
        {vacancies &&
          vacancies.map(({ id, title, location, description }) => (
            <script key={id} type="application/ld+json">
              {`{
                "@context":"http://schema.org",
                "@type":"JobPosting",
                "title" : "${title}",
                "@id":"${id}",
                "description":"${VacancyParser.getVacancyText(description)}",
                "jobLocation": {
                "@type": "Place",
                  "address": {
                    "addressCountry": "${location}"
                  }
                }
              }`}
            </script>
          ))}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Career",
                "item": "https://etring.com/career/"
              }]
            }`}
        </script>
        <GoogleTagManager />
        <FacebookPixel />
      </Helmet>
      {!vacancies && !vacanciesListErr ? (
        renderLoader()
      ) : (
        <CareerContent
          vacancies={vacancies}
          vacanciesListErr={vacanciesListErr}
          onRowClick={handleTableRowClick}
        />
      )}
    </div>
  );
};

const CareerWithConsumer = () => {
  return (
    <VacanciesConsumer>
      {({ vacancies, setVacancies }) => {
        return <Career vacancies={vacancies} setVacancies={setVacancies} />;
      }}
    </VacanciesConsumer>
  );
};
export default CareerWithConsumer;
