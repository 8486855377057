import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// Create the context
const VacanciesContext = createContext();

const VacanciesProvider = ({ children }) => {
  const [vacancies, setVacancies] = useState(null);
  const [chosenVacancy, setChosenVacancy] = useState(null);

  return (
    <VacanciesContext.Provider value={{ vacancies, setVacancies, setChosenVacancy, chosenVacancy }}>
      {children}
    </VacanciesContext.Provider>
  );
};

// PropTypes validation for the provider
VacanciesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

// Export both the Provider and the Context
export { VacanciesProvider, VacanciesContext };

// Define a separate Consumer component for usage
const VacanciesConsumer = VacanciesContext.Consumer;
export { VacanciesConsumer };
