import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
  text,
  link,
  onClick,
  disabled = false,
  headerButton,
  footerButton,
  toggled,
  styles,
  customClass,
  blank,
}) => {
  const clickHandler = () => {
    if (!link && !onClick) return;
    if (blank) {
      if (link) {
        window.open(link, '_blank');
      }

      if (onClick) {
        onClick();
      }
    } else {
      link && document.location.replace(link);
      onClick && onClick();
    }
  };

  return (
    <button
      style={styles}
      className={`${
        customClass
          ? customClass
          : headerButton
          ? 'buttonHeader'
          : footerButton
          ? 'buttonFooter'
          : toggled
          ? 'button-toggled'
          : 'button'
      } ${disabled ? 'is-disabled' : ''}`}
      onClick={clickHandler}
      role={link ? 'link' : 'button'}
      type={!link ? 'submit' : 'button'}
      disabled={disabled}
    >
      {/* <span className="button-overlay">{text}</span> */}
      {text}
    </button>
  );
};

export default Button;

Button.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  headerButton: PropTypes.bool,
  toggled: PropTypes.bool,
};

export const BackButton = ({ text, onClick }) => {
  const clickHandler = () => {
    onClick && onClick();
  };
  return (
    <div className="back-btn" onClick={clickHandler}>
      <div className="back-btn-inner">
        <div className="back-btn-arrow" />
        <span className="back-btn-title">{text}</span>
      </div>
    </div>
  );
};

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
